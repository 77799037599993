import {
    CalendarIcon,
    CommonLang,
    DatePickerField,
    DateRangePickerComponent,
    FIELD_WITH_ERROR_FLASH_CLASS,
    FileInputField,
    KeyboardDatePickerField,
    MobileDatePickerField,
    NumberFormatCustom,
    PrilohaBase,
    PrilohaKNahrani,
    Span,
    TooltipIcon,
    useMatches,
} from '@eon.cz/apollo13-frontend-common';
import {Adresa, PovoleneTypyPriloh, PrilohaTyp, SmlouvaOdbernehoMistaTypMereni} from '@eon.cz/apollo13-graphql';
import {CloseOutlined, LocationOffOutlined, LocationOnOutlined} from '@mui/icons-material';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from '@mui/material';
import {format, isValid} from 'date-fns';
import {ChangeEvent, ComponentType, ReactNode, useState} from 'react';
import {Controller, FieldError, FieldValues, RegisterOptions, useFormContext, useWatch} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {OpositeDate, formatDate, parseDigits} from '../../../Utils';
import {getWebGeoLocation, useFetchGoogleAPI} from '../../../Utils/AddressUtils';
import {sendDataToRN} from '../../services/CommonService';
import {getFromLS, useTablet} from '../../services/PageService';

type SelectData = {value: string | number; label: string};

type DateProps = {
    readonly minDate?: Date | string;
    readonly maxDate?: Date | string;
    readonly disableFuture?: boolean;
    readonly disablePast?: boolean;
    readonly showTodayButton?: boolean;
    readonly datumDo?: string | null;
    readonly datumOd?: string | null;
    readonly oposite?: OpositeDate;
    readonly views?: Array<'day' | 'month' | 'year'>;
    readonly keyboard?: boolean;
    readonly shouldDisableDate?: boolean;
    readonly firstOfMonth?: boolean;
    readonly isCustomDateSelection?: boolean;
    readonly lastOfMonth?: boolean;
};
type FileProps = {
    readonly handleDelete?: (prilohaId: string) => Promise<void>;
    /**
     * Seznam archivovaných příloh (= příloh už v databázi)
     */
    readonly archivovane?: PrilohaBase[];

    /**
     * Seznam příloh k nahrání - na začátku prázdný seznam, pak hodnota, která byla nastavena přes onChangeKNahrani
     */
    readonly kNahrani: PrilohaKNahrani[];

    /**
     * Maximální počet příloh, které je možné nahrát
     */
    readonly limit: number | undefined;

    /**
     * Povolené typy příloh
     */
    readonly povoleneTypyPriloh?: PovoleneTypyPriloh;

    /**
     * Kontroluje, zda byl vybrán typ přílohy u všech souborů
     */
    readonly isAllSetTypyPriloh: boolean;
    readonly isValidationError: boolean;
    /**
     * Voláno pokud se mají změnit přílohy k nahrání
     */
    readonly onChangeKNahrani: (kNahrani: PrilohaKNahrani[]) => void;

    /**
     * U nahrávané přílohy změní její typ podle ID v local state
     */
    readonly onChangeTypPrilohy?: (id: number, typ: PrilohaTyp) => void;

    /**
     * Nastaví hodnotu podle toho, jestli validace příloh vrátí chybu nebo ne
     */
    readonly setIsValidationError: (error: boolean) => void;
    readonly allowFileExtensions?: string[];
    readonly validateAllPrilohySize?: boolean;
    readonly mutatedPovoleneTypyPriloh?: PovoleneTypyPriloh;
    readonly customErrors?: {message: string}[];
    readonly MAX_FILE_MB_SIZE?: number;
    readonly povinneTypyPrilohy?: PrilohaTyp[];
    readonly multiple?: boolean;
};

type Props = {
    readonly label?: string;
    readonly id?: string;
    readonly longLabel?: boolean;
    readonly labelRawText?: string;
    readonly helperText?: string;
    readonly defaultValue?: any;
    readonly required?: boolean;
    readonly autofocus?: boolean;
    readonly error?: FieldError;
    readonly startAdornment?: ReactNode;
    readonly endAdornment?: ReactNode;
    readonly passwordStrength?: ReactNode;
    readonly disabled?: boolean;
    readonly selectData?: SelectData[];
    readonly tooltip?: string | null;
    readonly tooltipValue?: any;
    readonly name: string;
    readonly CustomComponent?: ComponentType<any>;
    readonly selectedItem?: any;
    readonly customSelectComponent?: ReactNode;
    readonly autocomplete?: 'given-name' | 'family-name' | 'email' | 'tel' | 'street-address' | 'postal-code' | 'country' | 'organization' | 'off';
    readonly customChange?: (event: any) => void;
    readonly onOpen?: (event: any) => void;
    readonly onClose?: (event: any) => void;
    readonly isObec?: boolean;
    readonly placeholder?: string;
    readonly trimSpace?: boolean;
    readonly passwordHint?: string;
    // for OdberneMistoElektrinaAutocomplete
    readonly disableClear?: boolean;
    readonly ean?: boolean;
    readonly eic?: boolean;
    readonly vybranaAdresa?: boolean;
    readonly adresaObchodnihoPartnera?: Adresa | null;
    readonly children?: ReactNode;
    readonly multiline?: boolean;
    readonly useInputValue?: boolean;
    readonly initialValue?: string | number;
    readonly typMereni?: SmlouvaOdbernehoMistaTypMereni;
    readonly titleValue?: any;
    readonly mobileNumberOnly?: boolean;
    readonly loading?: boolean;
    readonly geoLocation?: boolean;
    readonly openHandleForClose?: boolean;
    readonly selectVariant?: 'standard' | 'outlined' | 'filled';
    readonly rules?: Omit<RegisterOptions<FieldValues, string>, 'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'> | undefined;
};

type BaseProps =
    | ({
          readonly type: 'text' | 'select' | 'number' | 'checkbox' | 'custom' | 'password' | 'radio' | 'radioGroup' | 'tel';
      } & Props)
    | ({
          readonly type: 'file';
      } & FileProps &
          Props)
    | ({
          readonly type: 'date' | 'dateRange';
      } & DateProps &
          Props);

export const FormInput = (props: BaseProps) => {
    const {
        type = 'text',
        required = false,
        disabled = false,
        name,
        label,
        labelRawText,
        startAdornment,
        tooltip,
        selectData,
        CustomComponent,
        customSelectComponent,
        autocomplete,
        autofocus,
        defaultValue,
        customChange,
        selectedItem,
        archivovane,
        povoleneTypyPriloh,
        handleDelete,
        isAllSetTypyPriloh,
        kNahrani,
        limit,
        onChangeKNahrani,
        onChangeTypPrilohy,
        setIsValidationError,
        isValidationError,
        allowFileExtensions,
        minDate,
        maxDate,
        disableFuture,
        disablePast,
        isObec,
        multiline,
        validateAllPrilohySize,
        placeholder,
        trimSpace,
        endAdornment,
        passwordStrength,
        passwordHint,
        disableClear,
        ean,
        eic,
        adresaObchodnihoPartnera,
        vybranaAdresa,
        datumDo,
        datumOd,
        oposite,
        showTodayButton,
        views,
        keyboard = true,
        helperText,
        children,
        mutatedPovoleneTypyPriloh,
        customErrors,
        onOpen,
        MAX_FILE_MB_SIZE,
        typMereni,
        shouldDisableDate,
        firstOfMonth,
        lastOfMonth,
        tooltipValue,
        initialValue,
        titleValue,
        povinneTypyPrilohy,
        mobileNumberOnly,
        loading,
        isCustomDateSelection,
        longLabel,
        geoLocation,
        openHandleForClose = true,
        onClose,
        multiple,
        selectVariant,
        id,
        rules,
        useInputValue,
    } = props as BaseProps & FileProps & DateProps;
    const {formatMessage} = useIntl();
    const matches = useMatches();
    const isTablet = useTablet();

    const {setLoading, loading: loadingGeoData} = useFetchGoogleAPI({isObec});

    const {control, getFieldState, formState, setValue} = useFormContext();

    const [openCalendar, setOpenCalendar] = useState(false);

    const location = getFromLS('mobile')?.location;
    const isMobile = getFromLS('mobile')?.isMobile;
    const locationGranted = getFromLS('mobile')?.locationGranted;

    const selectCustomDate: boolean | undefined = useWatch({
        control,
        name: 'selectCustomDate',
    });

    const handleOpenCloseCalendar = () => setOpenCalendar((prevState) => !prevState);

    const handleGetGeoLocationFromWebAPI = () => {
        if (!locationGranted && isMobile) {
            sendDataToRN({openLocationPermission: true});
            return;
        }
        getWebGeoLocation(name, location?.coords.latitude, location?.coords.longitude, setValue, setLoading, isObec);
    };

    if (type === 'select') {
        return (
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({field: {onChange, value, onBlur}, fieldState: {error}}) => {
                    const handleOnChange = (event: SelectChangeEvent<any>) => {
                        customChange?.(event.target.value);
                        onChange(event);
                    };
                    return (
                        <FormControl error={!!error} fullWidth margin="dense" className={error ? FIELD_WITH_ERROR_FLASH_CLASS : undefined}>
                            <InputLabel sx={{marginLeft: -1.8, mt: 0.5, zIndex: 0}}>
                                <Typography component="span" sx={{color: disabled ? 'text.disabled' : 'inherit'}}>
                                    <Box component="span" sx={{fontWeight: 'bold'}}>
                                        {`${formatMessage({id: label})}${required ? ' *' : ''}`}
                                    </Box>
                                </Typography>
                            </InputLabel>
                            <Select
                                onBlur={onBlur}
                                value={value ?? ''}
                                onChange={handleOnChange}
                                variant={selectVariant}
                                required
                                disabled={disabled}
                                autoComplete={autocomplete}
                                onOpen={onOpen}
                                onClose={openHandleForClose ? onOpen : onClose}
                                input={
                                    <Input
                                        startAdornment={startAdornment}
                                        endAdornment={
                                            tooltip ? (
                                                <TooltipIcon
                                                    textRaw={typeof tooltip === 'string' ? tooltip : undefined}
                                                    htmlText={typeof tooltip === 'string' ? undefined : tooltip}
                                                    placement="top"
                                                    color="primary"
                                                    fontSize="small"
                                                    showIcon
                                                    inline
                                                />
                                            ) : undefined
                                        }
                                    />
                                }
                                sx={{
                                    '& .MuiSelect-icon': {
                                        color: 'primary.main',
                                        marginRight: tooltip ? 2 : undefined,
                                    },
                                }}
                                data-testid={`form-field-${name}`}
                            >
                                {!customSelectComponent &&
                                    selectData?.map((item) => (
                                        <MenuItem sx={{whiteSpace: 'normal', wordWrap: 'break-word'}} key={item.value} value={item.value}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                {customSelectComponent}
                                {children}
                            </Select>
                            {helperText && <FormHelperText style={{marginLeft: '0px'}}>{helperText}</FormHelperText>}
                            {!!error && <FormHelperText style={{marginLeft: '0px'}}>{error.message}</FormHelperText>}
                        </FormControl>
                    );
                }}
            />
        );
    }

    if (type === 'text' || type === 'number' || type === 'password' || type === 'tel') {
        return (
            <Controller
                control={control}
                name={name}
                defaultValue={defaultValue}
                render={({field, fieldState: {error}}) => {
                    const handleOnChange = (event: any) => {
                        const trimmedValue = trimSpace ? event.target.value.trim() : event.target.value;
                        customChange?.(trimmedValue);
                        field.onChange(trimmedValue);
                    };
                    return (
                        <TextField
                            {...field}
                            type={type === 'password' ? 'password' : type === 'tel' ? 'tel' : 'text'}
                            id={field.name}
                            autoFocus={autofocus}
                            value={field.value ?? ''}
                            onChange={handleOnChange}
                            autoComplete={autocomplete}
                            disabled={disabled}
                            multiline={multiline}
                            maxRows={10}
                            className={error ? FIELD_WITH_ERROR_FLASH_CLASS : undefined}
                            data-testid={`form-field-${name}`}
                            placeholder={placeholder ? formatMessage({id: placeholder}) : ''}
                            InputProps={{
                                inputComponent: type === 'number' && !mobileNumberOnly ? NumberFormatCustom : undefined,
                                startAdornment: startAdornment ? (
                                    <InputAdornment position="start" style={{marginBottom: 3}}>
                                        {startAdornment}
                                    </InputAdornment>
                                ) : undefined,
                                endAdornment: tooltip ? (
                                    <TooltipIcon text={tooltip} value={tooltipValue} placement="top" color="primary" fontSize="small" showIcon />
                                ) : endAdornment ? (
                                    endAdornment
                                ) : undefined,
                            }}
                            InputLabelProps={{style: {display: 'flex', width: 'max-content'}}}
                            inputProps={
                                type === 'number'
                                    ? {
                                          inputMode: 'numeric',
                                          pattern: '/^-?d+(?:.d+)?$/g',
                                      }
                                    : undefined
                            }
                            label={
                                <Typography component="span">
                                    <Box
                                        component="span"
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: longLabel && !field.value ? '0.8rem' : 'inherit',
                                        }}
                                    >
                                        {formatMessage({id: label})}
                                    </Box>
                                </Typography>
                            }
                            fullWidth
                            variant="standard"
                            margin="dense"
                            error={!!error || !!passwordHint}
                            helperText={
                                error?.message ?? (
                                    <>
                                        <Typography variant="caption" color="textSecondary">
                                            {passwordHint}
                                        </Typography>
                                        {passwordStrength}
                                    </>
                                )
                            }
                            required={required}
                        />
                    );
                }}
            />
        );
    }

    if (type === 'checkbox') {
        return (
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({field: {onChange, onBlur, value, name}, fieldState: {error}}) => {
                    const handleOnChange = (event: any) => {
                        customChange?.(event);
                        onChange(event);
                    };
                    return (
                        <FormControl error={!!error} fullWidth margin="dense" className={error ? FIELD_WITH_ERROR_FLASH_CLASS : undefined}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={name}
                                        checked={value}
                                        onChange={handleOnChange}
                                        onBlur={onBlur}
                                        color="primary"
                                        disabled={disabled}
                                        data-testid={`form-field-${name}`}
                                    />
                                }
                                label={
                                    <TooltipIcon text={tooltip} value={tooltipValue} placement="top" color="primary" fontSize="small" showIcon>
                                        <Typography component="span">
                                            <Box
                                                component="span"
                                                sx={{
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {required ? (
                                                    <span>
                                                        {labelRawText ?? formatMessage({id: label}, titleValue)} <sup>&nbsp;*</sup>
                                                    </span>
                                                ) : (
                                                    labelRawText ?? formatMessage({id: label}, titleValue)
                                                )}
                                            </Box>
                                        </Typography>
                                    </TooltipIcon>
                                }
                            />
                            {!!error?.message && (
                                <Box sx={{ml: -1.5, mt: -1.5}}>
                                    <FormHelperText data-testid={`form-field-error-${name}`} error>
                                        {error.message}
                                    </FormHelperText>
                                </Box>
                            )}
                        </FormControl>
                    );
                }}
            />
        );
    }
    if (type === 'radio') {
        return (
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({field: {onChange, onBlur, value, name}, fieldState: {error}}) => {
                    const handleOnChange = (event: any) => {
                        customChange?.(event);
                        onChange(event);
                    };
                    return (
                        <FormControl error={!!error} fullWidth margin="dense" className={error ? FIELD_WITH_ERROR_FLASH_CLASS : undefined}>
                            <FormControlLabel
                                control={
                                    <Radio
                                        name={name}
                                        value={value}
                                        onChange={handleOnChange}
                                        onBlur={onBlur}
                                        data-testid={`form-field-${name}`}
                                        required={required}
                                        color="primary"
                                        disabled={disabled}
                                    />
                                }
                                label={
                                    <Typography component="span">
                                        <Box
                                            component="span"
                                            sx={{
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {formatMessage({id: label})}
                                        </Box>
                                    </Typography>
                                }
                                disabled={disabled}
                            />
                        </FormControl>
                    );
                }}
            />
        );
    }
    if (type === 'radioGroup') {
        return (
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({field: {onChange, value, onBlur, name}, fieldState: {error}}) => {
                    const handleOnChange = (_: ChangeEvent<HTMLInputElement>, val: string | number) => {
                        customChange?.(val);
                        onChange(val);
                    };
                    return (
                        <FormControl error={!!error} fullWidth margin="dense" className={error ? FIELD_WITH_ERROR_FLASH_CLASS : undefined}>
                            <Typography component="span" style={{marginTop: 12}}>
                                <Box
                                    component="span"
                                    sx={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {formatMessage({id: label})}
                                </Box>
                            </Typography>
                            {!!error?.message && (
                                <Box>
                                    <FormHelperText data-testid={`form-field-error-${name}`} error>
                                        {error.message}
                                    </FormHelperText>
                                </Box>
                            )}
                            <RadioGroup
                                aria-label={label}
                                name={name}
                                value={value ?? initialValue}
                                onChange={handleOnChange}
                                onBlur={onBlur}
                                data-testid={`form-field-${name}`}
                            >
                                {selectData?.map((data) => {
                                    return (
                                        <FormControlLabel
                                            key={data.value}
                                            value={data.value}
                                            control={<Radio disabled={disabled && Number(data.value) <= Number(initialValue)} />}
                                            label={
                                                <Typography component="span">
                                                    <Box
                                                        component="span"
                                                        sx={{
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        {data.label}
                                                    </Box>
                                                </Typography>
                                            }
                                        />
                                    );
                                })}
                            </RadioGroup>
                        </FormControl>
                    );
                }}
            />
        );
    }
    if (type === 'date') {
        return (
            <Controller
                control={control}
                name={name}
                defaultValue={defaultValue}
                render={({field: {onChange, value, onBlur}, fieldState: {error}}) => {
                    const handleOnChange = (event: any) => {
                        customChange?.(event);
                        onChange(event);
                    };
                    return (
                        <>
                            {keyboard && !(matches || isTablet) && (
                                <KeyboardDatePickerField
                                    name={name}
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={handleOnChange}
                                    label={label}
                                    error={!!error?.message}
                                    helperText={error ? error.message : null}
                                    required={required}
                                    minDate={minDate as Date}
                                    maxDate={maxDate as Date}
                                    disableFuture={disableFuture}
                                    disablePast={disablePast}
                                    data-testid={`form-field-${name}`}
                                    shouldDisableDate={shouldDisableDate}
                                    firstOfMonth={firstOfMonth}
                                    lastOfMonth={lastOfMonth}
                                    tooltip={
                                        <TooltipIcon
                                            text={tooltip}
                                            value={tooltipValue}
                                            placement="top"
                                            color={error ? 'error' : 'primary'}
                                            fontSize="small"
                                            showIcon
                                        />
                                    }
                                />
                            )}
                            {!keyboard && !(matches || isTablet) && (
                                <DatePickerField
                                    name={name}
                                    value={value ?? ''}
                                    onChange={handleOnChange}
                                    onBlur={onBlur}
                                    error={!!error?.message}
                                    label={label}
                                    helperText={error ? error.message : null}
                                    minDate={minDate as Date}
                                    maxDate={maxDate as Date}
                                    disableFuture={disableFuture}
                                    disablePast={disablePast}
                                    data-testid={`form-field-${name}`}
                                    views={views}
                                    required={required}
                                />
                            )}
                            {(matches || isTablet) && (
                                <MobileDatePickerField
                                    name={name}
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={handleOnChange}
                                    label={label}
                                    error={!!error?.message}
                                    helperText={error ? error.message : null}
                                    required={required}
                                    minDate={minDate as Date}
                                    maxDate={maxDate as Date}
                                    disableFuture={disableFuture}
                                    disablePast={disablePast}
                                    data-testid={`form-field-${name}`}
                                    shouldDisableDate={shouldDisableDate}
                                    firstOfMonth={firstOfMonth}
                                    lastOfMonth={lastOfMonth}
                                    tooltip={
                                        <TooltipIcon
                                            text={tooltip}
                                            value={tooltipValue}
                                            placement="top"
                                            color={error ? 'error' : 'primary'}
                                            fontSize="small"
                                            showIcon
                                        />
                                    }
                                />
                            )}
                        </>
                    );
                }}
            />
        );
    }
    if (type === 'dateRange') {
        return (
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({field: {onChange, value, onBlur, name}, fieldState: {error}}) => {
                    const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        const value = e.target.value;
                        const date = value.length === 5 || value.length === 2 ? value : formatDate(value);
                        const isValidDate = isValid(new Date(date?.split('.').reverse().join('-'))) && date.length === 10;
                        const valueToSet = isValidDate ? format(new Date(date?.split('.').reverse().join('-')), 'yyyy-MM-dd') : date;
                        onChange(date.length < 10 ? value : valueToSet);
                    };
                    const handleOnKeyPress = (e: any) => {
                        // zachycení stisknutí tečky
                        if (e?.key === '.') {
                            const inputVal = e.target.value;

                            // rozparsuje input
                            const digits = parseDigits(inputVal);

                            // rozdělí hodnotu na den, měsíc a rok
                            let days = digits.slice(0, 2);
                            let months = digits.slice(2, 4);
                            const years = digits.slice(4, 8);

                            const dayNum = Number(days);
                            const monthNum = Number(months);

                            if (!days.startsWith('0') && dayNum > 0 && dayNum < 10) {
                                // pokud je den v rozmezí 1-9, přidá na začátek nulu
                                days = `0${days}`;
                            }
                            if (!months.startsWith('0') && monthNum > 0 && monthNum < 10) {
                                // pokud je měsíc v rozmezí 1-9, přidá na začátek nulu
                                months = `0${months}`;
                            }

                            // upravené datum
                            const date = `${days}${months ? '.' : ''}${months}${years}`;

                            // update input value
                            onChange(date);
                        }
                    };
                    const handleClear = () => onChange('');
                    return (
                        <>
                            <TextField
                                className={error ? FIELD_WITH_ERROR_FLASH_CLASS : undefined}
                                fullWidth
                                variant="standard"
                                margin={'dense'}
                                label={formatMessage({id: label})}
                                value={
                                    isCustomDateSelection
                                        ? `${format(value?.startDate ?? new Date(), 'dd.MM.yyyy')} - ${format(value?.endDate ?? new Date(), 'dd.MM.yyyy')}`
                                        : value?.length === 10 && isValid(new Date(value))
                                          ? format(new Date(value), 'dd.MM.yyyy')
                                          : value ?? ''
                                }
                                onBlur={onBlur}
                                onChange={handleChange}
                                onKeyPress={handleOnKeyPress}
                                error={!!error}
                                helperText={error ? error.message : null}
                                required={true}
                                disabled={!selectCustomDate && isCustomDateSelection}
                                InputProps={{
                                    readOnly: !selectCustomDate && isCustomDateSelection,
                                    endAdornment: (
                                        <Span sx={{mr: -7.5, display: 'flex'}}>
                                            {!!value && (
                                                <TooltipIcon
                                                    htmlText={<FormattedMessage id={CommonLang.FORM_DATEPICKER_TOOLTIP_SMAZAT} />}
                                                    aria-label="delete"
                                                    showIcon={false}
                                                >
                                                    <IconButton
                                                        data-testid={`form-field-${name}-close-button`}
                                                        onClick={handleClear}
                                                        size="small"
                                                        sx={{width: 28, height: 28}}
                                                        disabled={disabled}
                                                    >
                                                        <CloseOutlined color={error ? 'error' : 'primary'} fontSize="small" />
                                                    </IconButton>
                                                </TooltipIcon>
                                            )}
                                            <TooltipIcon
                                                htmlText={<FormattedMessage id={CommonLang.FORM_DATEPICKER_TOOLTIP_KALENDAR} />}
                                                aria-label="calendar-pick"
                                                showIcon={false}
                                            >
                                                <IconButton
                                                    data-testid={`form-field-${name}-open-button`}
                                                    onClick={handleOpenCloseCalendar}
                                                    size="small"
                                                    sx={{width: 28, height: 28, marginRight: 8}}
                                                    disabled={disabled}
                                                >
                                                    <CalendarIcon color={error ? 'error' : 'primary'} fontSize="small" />
                                                </IconButton>
                                            </TooltipIcon>
                                        </Span>
                                    ),
                                }}
                            />
                            {openCalendar && (
                                <DateRangePickerComponent
                                    open={openCalendar}
                                    onClose={handleOpenCloseCalendar}
                                    datumDo={datumDo || value}
                                    onChange={customChange}
                                    change={setValue}
                                    oposite={oposite}
                                    datumOd={datumOd || value}
                                    minDate={minDate as Date}
                                    maxDate={maxDate as Date}
                                    clearData={handleClear}
                                    name={name}
                                    disabledToday={showTodayButton}
                                    data-testid={`form-field-${name}`}
                                />
                            )}
                            {/* {error && <FormHelperText>{error.message}</FormHelperText>} */}
                        </>
                    );
                }}
            />
        );
    }

    if (type === 'custom' && CustomComponent) {
        return (
            <Controller
                control={control}
                name={name}
                rules={rules}
                defaultValue={defaultValue}
                render={({field: {onChange, value, onBlur}, fieldState: {error}}) => {
                    const handleOnChange = (event: any) => {
                        customChange?.(event);
                        onChange(event);
                    };
                    return (
                        <CustomComponent
                            {...{
                                error,
                                disabled,
                                label: formatMessage({id: label}),
                                disableClear,
                                ean,
                                eic,
                                adresaObchodnihoPartnera,
                                vybranaAdresa,
                                defaultValue,
                                typMereni,
                                loading: loadingGeoData || loading,
                                tooltipValue,
                                id,
                                useInputValue,
                            }}
                            selectedItem={selectedItem}
                            name={name}
                            value={value}
                            onBlur={onBlur}
                            onChange={handleOnChange}
                            isObec={isObec}
                            data-testid={`form-field-${name}`}
                            placeholder={placeholder}
                            endAdornment={
                                geoLocation ? (
                                    <Span onClick={handleGetGeoLocationFromWebAPI} sx={{cursor: 'pointer'}} aria-hidden="true">
                                        {locationGranted ? <LocationOnOutlined /> : <LocationOffOutlined />}
                                    </Span>
                                ) : (
                                    endAdornment
                                )
                            }
                            startAdornment={startAdornment}
                            tooltipText={tooltip}
                            required={required}
                            defaultValue={defaultValue}
                        />
                    );
                }}
            />
        );
    }

    if (type === 'file') {
        return (
            <>
                <Controller
                    name={name}
                    control={control}
                    defaultValue={defaultValue}
                    render={({field: {onChange}, fieldState: {error}}) => (
                        <FileInputField
                            name={name}
                            type="file"
                            kNahrani={kNahrani}
                            archivovane={archivovane}
                            onChangeKNahrani={onChangeKNahrani}
                            isAllSetTypyPriloh={isAllSetTypyPriloh}
                            onChangeTypPrilohy={onChangeTypPrilohy}
                            povoleneTypyPriloh={povoleneTypyPriloh}
                            allowFileExtensions={allowFileExtensions}
                            limit={limit}
                            onDelete={handleDelete}
                            setIsValidationError={setIsValidationError}
                            onChange={onChange}
                            error={!!error}
                            data-testid={`form-field-${name}`}
                            customErrors={customErrors ? customErrors : !!error ? [{message: error.message}] : []}
                            validateAllPrilohySize={validateAllPrilohySize}
                            mutatedPovoleneTypyPriloh={mutatedPovoleneTypyPriloh}
                            required={required}
                            MAX_FILE_MB_SIZE={MAX_FILE_MB_SIZE}
                            povinneTypyPrilohy={povinneTypyPrilohy}
                            multiple={multiple}
                        />
                    )}
                />
                {(isValidationError || getFieldState(name)?.invalid, formState) && (
                    <FormHelperText component="span" data-testid={`form-field-error-${name}`} sx={{color: 'error.main'}}>
                        {isValidationError ? <FormattedMessage id="priloha.nejde.vlozit" /> : getFieldState(name)?.error?.message}
                    </FormHelperText>
                )}
            </>
        );
    }

    return null;
};
